.root {
    height  : 100vh;
    position: relative;

    .main-header {
        background: #fff;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
        padding   : 0;

        .content {
            display        : flex;
            margin         : 0 auto;
            height         : 100%;
            max-width      : 144rem;
            width          : 100%;
            justify-content: space-between;
            align-items    : center;

            >.left,
            >.right {
                height     : 100%;
                display    : flex;
                align-items: center;
            }
        }

        .logobox {
            font-weight : 700;
            font-size   : 2rem;
            color       : rgba(0, 0, 0, 0.65);
            margin-right: 4.4rem;

            .logo {
                height        : 4rem;
                width         : 4rem;
                vertical-align: middle;
                margin-right  : 1.6rem;
            }
        }

        .navmenu {

            :global(.ant-menu-item),
            :global(.ant-menu-submenu) {
                color: rgba(0, 0, 0, 0.4);

                &:global(.ant-menu-item-active),
                &:global(.ant-menu-item-selected),
                :global(.ant-menu-submenu-title):hover,
                &:global(.ant-menu-submenu-selected),
                &:hover {
                    color: rgba(0, 0, 0, 0.9);
                }

                &::after {
                    border: none;
                }
            }
        }

        .right {

            :global(.anticon),
            .userinfo {
                color: rgba(0, 0, 0, 0.4);

                &:hover,
                &:hover :global(.anticon) {
                    color: rgba(0, 0, 0, 0.9);
                }
            }

            .customheader {
                margin-right: 1.6rem;
                align-items : center;
                display     : flex;
            }

            .arch {
                // $font-size : 1.6rem;
                font-size  : 1.6rem;
                line-height: 3rem;
            }
        }
    }

    .main-body {
        overflow: overlay;
        padding : 2.4rem;

        >.footer {
            text-align: center;
            margin-top: 1rem;

            .logo {
                margin-bottom: .8rem;
            }

            .copyright {
                color    : rgba(0, 0, 0, 0.4);
                font-size: 1.2rem;
            }

            .links {
                a {
                    margin-right: 5px;
                    color       : #383838;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        >:global(.ant-drawer) {
            top   : unset;
            right : unset;
            height: calc(100% - 6.4rem);
            width : 100%;
        }
    }


}

@media only screen and (max-width: 1440px) {
    .root {
        .main-header {
            padding: 0 2.4rem;
        }
    }
}