.root {
    display        : flex;
    background     : #001529;
    color          : #fff;
    justify-content: center;
    align-items    : center;
    width          : 100vw;
    height         : 100vh;


    .box {
        width: 300px;
    }

    :global(.ant-form-item-label)>label {
        color: #fff;

        :global(.anticon) {
            color: #fff;
        }
    }

    .btnlogin {
        width: 100%;
    }

    .qrcode {
        text-align   : center;
        margin-bottom: 1.6rem;

        .qrcodebox {
            width     : 25.6rem;
            height    : 25.6rem;
            margin    : 1rem auto;
            border    : 5px solid #fff;
            box-sizing: content-box;

            canvas {
                width : inherit;
                height: inherit;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .root {
        padding: 1rem;

        :global(.ant-spin-nested-loading) {
            width: 100%;
        }

        .box {
            width: 100%;
        }
    }
}